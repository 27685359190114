import React from 'react'
import AppBar from '../../components/AppBar/AppBar';
import ResumeContent from '../../components/ResumeContent/ResumeContent';

const ResumePage = () => {
    return(
        <div>   
            <AppBar />
            <ResumeContent />
            
        </div>
        

    )
}

export default ResumePage;